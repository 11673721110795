
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus';

import searchComponent from '@/components/search-component/index.vue';
import tableComponent from '@/components/table-component/index.vue';
import dialogEdit from './components/dialog-edit.vue';
export default defineComponent({
    components: {
        searchComponent,
        tableComponent,
        dialogEdit
    },
    data() {
        return {
            searchList: {
                inputName: {
                    name: '搜索',
                    prop: 'inputName',
                    type: 'input',
                    value: '',
                    defaultValue: '',
                    placeholder: '请输入企业/项目名称'
                },
                createName: {
                    name: '创建人',
                    prop: 'createName',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    defaultValue: '',
                    options: [],
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    },
                },
                projectType: {
                    name: '项目类型',
                    prop: 'projectType',
                    type: 'select',
                    placeholder: '请选择',
                    value: '',
                    defaultValue: '',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '无打卡',
                            value: 0
                        },
                        {
                            label: '需打卡',
                            value: 1
                        },
                    ],
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    },
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            buttonList: [
                {
                    name: '导出名单',
                    icon: 'export',
                    fn: () => {
                        console.log(111);
                    }
                },
            ] as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'projectName',
                    label: '项目名称'
                },
                {
                    prop: 'customerName',
                    label: '所属企业'
                },
                {
                    prop: 'projectType',
                    label: '项目类型'
                },
                {
                    prop: 'amount',
                    label: '项目人数'
                },
                {
                    prop: 'createPeople',
                    label: '创建人'
                },
                {
                    prop: 'createDate',
                    label: '创建日期'
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 120,
                    fixed: 'right'
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [
                {
                    projectName: 'aa'
                },
                {
                    projectName: 'bb'
                },
                {
                    projectName: 'cc'
                },
                {
                    projectName: 'dd'
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            showDialog: false,
            pageInfo: {
                pageNum: 1,
                pageSize: 2,
                total: 4
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any
        }
    },
    methods: {
        handleSelectionChange (row: any) {
            console.log(1111, row)
        },
        handleArchive() {
            ElMessageBox.confirm(
                '归档后的项目，其他成员仍可查看。如需查看，请至“归档项目”中查看',
                '归档项目',
                {
                    confirmButtonText: '确定归档',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    console.log(1);
                })
                .catch(() => {
                    console.log(2);
                })
        },
        showEdit(row: any) {
            console.log(111, row);
            this.showDialog = true
        },
        createQuery() {
            const query = {} as any
            for (const key in this.searchList) {
                query[(this.searchList[key]).prop] = this.searchList[key].value
            }
            return query
        },
        handleSearch() {
            console.log(11, this.createQuery());
        },
        handleSizeChange(ev: number) {
            console.log(111, ev);
        },
        handleCurrentChange(ev: number) {
            console.log(222, ev);
        }
    }
})
