
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            dialogVisible: false,
            activeName: 'projectInfo',
            projectData: {
                companyName: {
                    prop: 'companyName',
                    name: '派遣单位',
                    disabled: true,
                    type: 'input',
                    value: ''
                },
                projectName: {
                    prop: 'projectName',
                    name: '项目名称',
                    disabled: false,
                    type: 'input',
                    value: ''
                },
                admin: {
                    prop: 'admin',
                    name: '管理员',
                    disabled: false,
                    type: 'select',
                    multiple: true,
                    value: [],
                    options: [
                        {
                            label: 1,
                            value: 1
                        },
                        {
                            label: 2,
                            value: 2
                        },
                    ],
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                },
            },
            projectRules: {
                'companyName.value': [
                    {
                        required: true,
                        message: '请输入派遣单位',
                        trigger: 'blur'
                    }
                ],
                'projectName.value': [
                    {
                        required: true,
                        message: '请输入项目名称',
                        trigger: 'blur'
                    }
                ],
                'admin.value': [
                    {
                        required: true,
                        message: '请选择负责人',
                        trigger: 'change'
                    }
                ],
            },
            attendanceData: {
                order: {
                    prop: 'order',
                    name: '排班方式',
                    disabled: true,
                    type: 'radio',
                    value: '',
                    options: [
                        {
                            label: '白班',
                            value: 1
                        },
                        {
                            label: '夜班',
                            value: 2
                        },
                    ]
                },
                ageLimit: {
                    prop: 'ageLimit',
                    name: '年龄限制',
                    disabled: false,
                    type: 'select',
                    multiple: false,
                    value: '',
                    options: [
                        {
                            label: 18,
                            value: 18
                        }
                    ],
                    defaultAttrs: {
                        label: 'label',
                        value: 'value'
                    }
                },
            },
            attendanceRules: {
                'order.value': [
                    {
                        required: true,
                        message: '请选择排班方式',
                        trigger: 'change'
                    }
                ],
                'ageLimit.value': [
                    {
                        required: true,
                        message: '请选择年龄限制',
                        trigger: 'change'
                    }
                ],
            },
        }
    },
    watch: {
        show: {
            handler(newval: boolean) {
                this.dialogVisible = newval
            },
            immediate: true
        },
        dialogVisible(newval: boolean) {
            this.$emit('update:show', newval)
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleClick () {

        },
        submit () {
            if (this.activeName === 'projectInfo') {
                (this.$refs.formRef1 as any).validate((valid: any) => {
                    if (valid) {
                        console.log('1submit!')
                    } else {
                        console.log('1error submit!')
                        return false
                    }
                })
            } else if (this.activeName === 'attendanceInfo') {
                (this.$refs.formRef2 as any).validate((valid: any) => {
                    if (valid) {
                        console.log('2submit!')
                    } else {
                        console.log('2error submit!')
                        return false
                    }
                })
            }
        }
    }
})
